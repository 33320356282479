<template>
  <div
    class="bg_col_fff bor_rad_8 box_sha_0_0_8_8_black_80_per p_16 m_0_16 m_t_16"
  >
    <div class="dis_flex ali_it_cen">
      <div class="flex_1 font_16 font_bold">省长列表</div>

      <!-- 选项卡 -->
      <div class="dis_flex ali_it_cen">
        <img
          class="w_16 h_16 dis_flex m_r_8"
          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/TsvtFIIYzRzkBuSudCrx.png"
        />

        <!-- 项 -->
        <div
          v-for="(item, index) in tabArr"
          :key="index"
          class="font_14"
          @click="
            tabIndex = index;
            getList();
          "
        >
          <span v-show="index > 0" class="m_0_8">/</span>
          <span :class="`${tabIndex == index ? 'col_2396F5' : ''}`">{{
            item.label
          }}</span>
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 表格 -->
    <div class="m_t_16">
      <!-- 列表 -->
      <vxe-table
        :loading="loading"
        :data="list"
        border
        resizable
        align="center"
      >
        <vxe-column title="省长名称" width="96" fixed="left">
          <template #default="{ row }">
            {{ row.brokerName || '-' }}
          </template>
        </vxe-column>

        <vxe-column title="进件数量" width="80">
          <template #default="{ row }">
            {{ columnAmount(row.loanCount, { fixed: 0 }) }}
          </template>
        </vxe-column>

        <vxe-column title="预审通过" width="80">
          <template #default="{ row }">
            {{ columnAmount(row.preCount, { fixed: 0 }) }}
          </template>
        </vxe-column>
        
        <vxe-column title="审批通过" width="80">
          <template #default="{ row }">
            {{ columnAmount(row.approveCount, { fixed: 0 }) }}
          </template>
        </vxe-column>

        <vxe-column title="成交数量" width="80">
          <template #default="{ row }">
            {{ columnAmount(row.putAmtCount, { fixed: 0 }) }}
          </template>
        </vxe-column>

        <vxe-column title="放款金额（元）" width="128">
          <template #default="{ row }">
            {{ columnAmount(row.putAmt) }}
          </template>
        </vxe-column>

        <!--  -->
      </vxe-table>

      <!--  -->
    </div>

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';
import { columnAmount } from '@/utils/tools';

import { brokerDashboardStatisticsPost } from '@/api/index';

export default {
  components: {},
  data() {
    return {
      loading: false,
      tabIndex: 0,
      list: [],

      //
    };
  },
  computed: {
    // 选项卡
    tabArr: () => [
      {
        label: '昨日',
        value: 'LAST_DAY',
      },
      { label: '当月', value: 'CURRENT_MONTH' },
      { label: '累计', value: '' },
    ],

    //
  },
  mounted() {
    this.getList();

    //
  },
  methods: {
    columnAmount,

    // 获取 列表
    async getList() {
      try {
        this.loading = true;

        const data = await brokerDashboardStatisticsPost({
          current: 1,
          size: 999999,
          duration: this.tabArr[this.tabIndex].value,
        });

        this.list = data.records;

        //
      } catch (err) {
        Toast(err);
      } finally {
        this.loading = false;
      }

      //
    },

    //
  },
};
</script>
